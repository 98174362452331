<template>
    <div>
        <basic-container>
            <avue-crud :option="tableOption"
                       :data="tableData"
                       v-model="tableFrom"
                       :table-loading="tableLoading"
                       :page.sync="page"
                       ref="crud"
                       @current-change="currentChange"
                       @size-change="sizeChange"
                       :before-open="beforeOpen"
                       @selection-change="selectionChange"
                       @refresh-change="refreshChange"
                       @row-save="handleSave"
                       @row-update="handleUpdate"
                       @row-del="handleDel">
                <template slot="name" slot-scope="scope">
                    <el-link @click="linkToLook(scope.row)">{{scope.row.name}}</el-link>
                </template>
                <template slot="status" slot-scope="scope">
                    <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                               @change="change(scope.row)"></el-switch>
                </template>
                <template slot="menuLeft">
                    <el-dropdown>
                        <el-button type="primary" size="small" icon="el-icon-plus" @click="addClick">{{$t("Add")}}
                        </el-button>
                        <!-- <el-button type="success" icon="el-icon-menu" size="small">
                            (全部状态)<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button> -->
                        <el-dropdown-menu slot="dropdown">
                            <!-- <el-dropdown-item>正常</el-dropdown-item>
                            <el-dropdown-item>开始</el-dropdown-item>
                            <el-dropdown-item>启动</el-dropdown-item>
                            <el-dropdown-item>禁用</el-dropdown-item>
                            <el-dropdown-item>🚀</el-dropdown-item> -->
                        </el-dropdown-menu>
                    </el-dropdown>
                    <!-- <el-button type="primary" size="small" icon="el-icon-discover" v-bind:disabled="disabled">审核</el-button>
                    <el-button type="warning" size="small" icon="el-icon-discover" v-bind:disabled="disabled">撤销审核</el-button> -->
                </template>
                <template slot="menuRight">
                    <el-select clearable v-model="work" placeholder="产品" size="small"
                               style="width:120px !important;marginRight: 5px;">
                        <el-option v-for="item in listworkStyle" :key="item.id" :label="item.name" :value="item.id">
                            {{item.name}}
                        </el-option>
                    </el-select>
                    <el-select clearable v-model="porccess" placeholder="设备机型" size="small"
                               style="width:120px !important;marginRight: 5px;">
                        <el-option v-for="(item,index) in listComputer" :key="index" :label="item.name"
                                   :value="item.id">{{item.name}}
                        </el-option>
                    </el-select>
                    <el-select clearable v-model="wkln" placeholder="工序" size="small"
                               style="width:120px !important;marginRight: 5px;">
                        <el-option v-for="(item,index) in listWkln" :key="index" :label="item.name" :value="item.id">
                            {{item.name}}
                        </el-option>
                    </el-select>
                    <el-select clearable v-model="status" placeholder="禁用状态" size="small"
                               style="width:100px !important;marginRight: 5px;">
                        <el-option label="启用" value="0">启用</el-option>
                        <el-option label="禁用" value="1">禁用</el-option>
                    </el-select>
                    <el-date-picker
                            v-model="timeValues"
                            style="width: 150px !important;"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            size="small"
                            type="date"
                            placeholder="更新时间">
                    </el-date-picker>
                    <!--<el-button type="primary" size="small" icon="el-icon-search" @click="search">{{$t("search")}}-->
                    <!--</el-button>-->
                    <!-- <el-button type="primary" size="small" icon="el-icon-search">高级搜索</el-button> -->
                </template>
                <!-- <template slot="menu">
                    <el-button type="text" size="mini" icon="el-icon-discover" style="color:red;">禁用</el-button>
                </template> -->
            </avue-crud>
        </basic-container>
    </div>
</template>
<script>
    import {baseList, planList, add, edit, remove, changeStatus} from "@/api/standardList";
    import {wklnList, productWork, machineModelList} from "@/api/config";

    export default {
        data() {
            return {
                tableLoading: false,
                tableFrom: {},
                porccess: "",
                work: "",
                wkln: "",
                listworkStyle: [],
                listComputer: [],
                status: "0",
                timeValues: "",
                wklnValue: "",
                listWkln: [],
                nameOrCode: "",
                departmentId: "1",
                page: {
                    pageSize: 20,
                    pagerCount: 5,
                    total: '',
                    pageCurrent: 1
                },
                disabled: true,
                tableData: [],
                tableOption: {
                    addBtn: false,
                    //   menuType:'icon',
                    //   delBtn: true,
                    //   menuWidth:200,
                    //   selection: true,
                    //   tip: false,
                    //   columnBtn : true,
                    delBtn: true,
                    menuWidth: 200,
                    selection: true,
                    tip: false,
                    columnBtn: false,
                    labelWidth: "120",
                    dialogFullscreen: true,
                    addBtnText: this.$t('ADD'),
                    delBtnText: this.$t('DELETE'),
                    editBtnText: this.$t('Edit'),
                    emptyText: this.$t('No Data'),
                    menuTitle: this.$t('OPERATION'),
                    column: [{
                        label: "工艺单号",
                        prop: "code",
                        slot: true
                    }, {
                        label: "设备机型",
                        prop: "machineModelCode"
                    }, {
                        label: "产品",
                        prop: "productName"
                    }, {
                        label: "规格",
                        prop: "productModels"
                    }, {
                        label: "生产工序",
                        prop: "processName"
                    }, {
                        label: "定长",
                        prop: "meters"
                    }, {
                        label: "更新日期",
                        prop: "updateTime"
                    }, {
                        label: "创建日期",
                        prop: "createTime",
                        dicData: []
                    }, {
                        label: "启用状态",
                        slot: true,
                        prop: "status",
                        dicData: [{
                            label: "是",
                            value: 1
                        }, {
                            label: "否",
                            value: 0
                        }]
                    }, {
                        label: "工艺员",
                        prop: "specUserName"
                    }, {
                        label: "创建人",
                        prop: "createUserName"
                    }]
                }
            }
        },
        created() {
            this.list();
            wklnList().then(res => {
                this.listWkln = res.data;
            });
            productWork().then(res => {
                this.listworkStyle = res.data.data;
            });
            machineModelList().then(res => {
                this.listComputer = res.data;
            });
        },
        methods: {
            currentChange(pageCurrent) {
                this.page.pageCurrent = pageCurrent;
                this.list();
            },
            sizeChange(pageSize) {
                this.page.pageCurrent = 1;
                this.page.pageSize = pageSize;
                this.list();
            },
            linkToLook(row) {
                this.$router.push({path: "lookstandrd", query: {pushData: row}});
            },
            beforeOpen(done, type) {
                console.log(this.tableFrom);
                this.$router.push({path: "editstandrd", query: {pushData: this.tableFrom}});
            },
            search() {
                this.list();
            },
            addClick() {
                this.$router.push({path: "addstandard"});
            },
            handleDel(row) {
                let msg;
                if (row.machineCodes == null) {
                    msg = "确定要删除吗"
                } else {
                    msg = "您所选择的设备机型标准工艺单已绑定设备，是否确认删除"
                }
                this.$confirm(msg, "提示", {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    remove(row.id).then((response) => {
                      console.log(response)
                      if (response.data.code == '0000') {
                        this.$message.success('删除成功')
                      }
                        this.list();
                    })
                })
            },
            handleUpdate(row, index, done) {
                edit(row).then(() => {
                  this.$message({
                    message: "修改成功",
                    type: "success"
                  });
                    this.list();
                    done();
                })
            },
            handleSave(row, done) {
                add(row).then(() => {
                    this.list();
                    done();
                })
            },
            change(row) {
                changeStatus(row).then(() => {
                    this.$message({
                        message: "修改成功",
                        type: "success"
                    });
                })
            },
            selectionChange(list) {
                console.log(list);
                list != "" ? this.disabled = false : this.disabled = true;
            },
            refreshChange() {
                this.list();
            },
            list() {
                baseList(this.page.pageCurrent, this.page.pageSize, this.work, this.porccess, this.wkln, this.status, this.timeValues).then(res => {
                    console.log('123', res);
                    if (res.data.data == null) {
                        this.tableData = [];
                        this.page.total = 0;
                    } else {
                        this.page.total = res.data.data.total
                        this.tableData = res.data.data.items;
                        //   this.tableData.forEach(v => {
                        //       v.status = v.status;
                        //   })
                    }
                })
            },
            envText: function () {
                return this.env
            }
        }
    }
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>
