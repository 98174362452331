var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                "table-loading": _vm.tableLoading,
                page: _vm.page,
                "before-open": _vm.beforeOpen
              },
              on: {
                "update:page": function($event) {
                  _vm.page = $event
                },
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange,
                "selection-change": _vm.selectionChange,
                "refresh-change": _vm.refreshChange,
                "row-save": _vm.handleSave,
                "row-update": _vm.handleUpdate,
                "row-del": _vm.handleDel
              },
              scopedSlots: _vm._u([
                {
                  key: "name",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-link",
                        {
                          on: {
                            click: function($event) {
                              return _vm.linkToLook(scope.row)
                            }
                          }
                        },
                        [_vm._v(_vm._s(scope.row.name))]
                      )
                    ]
                  }
                },
                {
                  key: "status",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: { "inactive-value": 1, "active-value": 0 },
                        on: {
                          change: function($event) {
                            return _vm.change(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.status,
                          callback: function($$v) {
                            _vm.$set(scope.row, "status", $$v)
                          },
                          expression: "scope.row.status"
                        }
                      })
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.tableFrom,
                callback: function($$v) {
                  _vm.tableFrom = $$v
                },
                expression: "tableFrom"
              }
            },
            [
              _c(
                "template",
                { slot: "menuLeft" },
                [
                  _c(
                    "el-dropdown",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            icon: "el-icon-plus"
                          },
                          on: { click: _vm.addClick }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("Add")) + "\n                    "
                          )
                        ]
                      ),
                      _c("el-dropdown-menu", {
                        attrs: { slot: "dropdown" },
                        slot: "dropdown"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "120px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: "产品",
                        size: "small"
                      },
                      model: {
                        value: _vm.work,
                        callback: function($$v) {
                          _vm.work = $$v
                        },
                        expression: "work"
                      }
                    },
                    _vm._l(_vm.listworkStyle, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.name) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "120px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: "设备机型",
                        size: "small"
                      },
                      model: {
                        value: _vm.porccess,
                        callback: function($$v) {
                          _vm.porccess = $$v
                        },
                        expression: "porccess"
                      }
                    },
                    _vm._l(_vm.listComputer, function(item, index) {
                      return _c(
                        "el-option",
                        {
                          key: index,
                          attrs: { label: item.name, value: item.id }
                        },
                        [_vm._v(_vm._s(item.name) + "\n                    ")]
                      )
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "120px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: "工序",
                        size: "small"
                      },
                      model: {
                        value: _vm.wkln,
                        callback: function($$v) {
                          _vm.wkln = $$v
                        },
                        expression: "wkln"
                      }
                    },
                    _vm._l(_vm.listWkln, function(item, index) {
                      return _c(
                        "el-option",
                        {
                          key: index,
                          attrs: { label: item.name, value: item.id }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.name) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "100px !important",
                        marginRight: "5px"
                      },
                      attrs: {
                        clearable: "",
                        placeholder: "禁用状态",
                        size: "small"
                      },
                      model: {
                        value: _vm.status,
                        callback: function($$v) {
                          _vm.status = $$v
                        },
                        expression: "status"
                      }
                    },
                    [
                      _c(
                        "el-option",
                        { attrs: { label: "启用", value: "0" } },
                        [_vm._v("启用")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: "禁用", value: "1" } },
                        [_vm._v("禁用")]
                      )
                    ],
                    1
                  ),
                  _c("el-date-picker", {
                    staticStyle: { width: "150px !important" },
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      size: "small",
                      type: "date",
                      placeholder: "更新时间"
                    },
                    model: {
                      value: _vm.timeValues,
                      callback: function($$v) {
                        _vm.timeValues = $$v
                      },
                      expression: "timeValues"
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }